<template>
  <UiPopup
    :model-value="modelValue"
    title="Move to Rotation"
    primary-button-text="Approve"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading || !checkedIds.length"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="approve"
  >
    <div>
      <p class="text-subhead-4 mb-2 text-additional-3-100">
        Oops! Someone already picked up the leads ({{ selectedIds.length - checkedIds.length }}).
      </p>
      <small
        >They have been automatically removed from your selection, as they are now assigned to other users. The
        remaining leads ({{ checkedIds.length }}) will be moved to rotation.</small
      >
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  selectedLeads: number[]
  checkedLeads: number[]
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)
const selectedIds = ref<number[]>(props.selectedLeads)
const checkedIds = ref<number[]>(props.checkedLeads)

const approve = async () => {
  loading.value = true
  try {
    const leadIds = selectedIds.value.filter((leadId: number) => checkedIds.value.includes(leadId))
    const data = await useCheckBulkActionInQueue('move_to_rotation', leadIds)
    if (data.lead_ids.length === leadIds.length) {
      await useBulkMoveToRotation(leadIds, true)
      emits('input')
      emits('update:modelValue', false)
    } else {
      selectedIds.value = leadIds
      checkedIds.value = data.lead_ids
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
